import axiosAdmin from '@/plugins/axiosAdmin';
const moduleTransaction = {
  state: () => ({
    transactions: [],
    transactionsPreOrder: [],
    transactionDetail: {},
    repaymentList: [],
    preOrderDetail: null,
  }),
  mutations: {
    setTransactions(state, payload) {
      state.transactions = payload;
    },
    setTransactionsPreOrder(state, payload) {
      state.transactionsPreOrder = payload;
    },
    setTransactionDetail(state, payload) {
      state.transactionDetail = payload;
    },
    setRepaymentList(state, payload) {
      state.repaymentList = payload;
    },
    setPreOrderDetail(state, payload) {
      state.preOrderDetail = payload;
    },
  },
  actions: {
    // get all transaction data from backend
    async getTransactions({ commit }, param) {
      await axiosAdmin
        .get(`/api/admin/transaction?${param}`)
        .then((resp) => {
          commit('setTransactions', resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get pre order product detail from backend
    async getPreOrderDetail({ commit }, query) {
      await axiosAdmin
        .get(`/api/admin/transaction/getPreorderDetailAdmin?${query}`)
        .then((response) => {
          commit('setPreOrderDetail', response.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get list transaction pre order from backend
    async getTransactionsPreOrder({ commit }, query) {
      await axiosAdmin
        .get(`/api/admin/transaction/getPreorderAdmin?${query}`)
        .then((resp) => {
          commit('setTransactionsPreOrder', resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get transaction detail from backend
    async getTransactionDetail({ commit }, uuid) {
      await axiosAdmin
        .get(`/api/admin/transaction/${uuid}`)
        .then((resp) => {
          commit('setTransactionDetail', resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // send new transaction data to backend
    async addTransaction(_, payload) {
      return axiosAdmin.post('/api/admin/transaction/add', payload);
    },

    // send new payment data to backend
    async payment(_, payload) {
      await axiosAdmin
        .post(`/api/customer/payment/${payload.id}`, payload.data)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // approve payment
    async approvePayment(_, payload) {
      await axiosAdmin
        .post(`/api/admin/payment/approve/${payload.id}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // reject payment
    async rejectPayment(_, id) {
      await axiosAdmin
        .post(`/api/admin/payment/reject/${id}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // set transaction status to done
    async doneTransaction(_, id) {
      await axiosAdmin
        .post(`/api/admin/transaction/done/${id}`, { review: '' })
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // set transaction status to ready stock
    async readyStock(_, payload) {
      const data = new FormData();
      data.append('duedate_payment', payload.dueDatePayment);
      await axiosAdmin
        .post(`/api/admin/transaction/ready-stock/${payload.id}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // set transaction status to on delivery
    async onDelivery(_, payload) {
      const data = new FormData();
      data.append('nomor_resi', payload.deliveryReceipt);
      data.append('amount_package', payload.amountPackage);
      data.append('expedition', payload.expedition);
      await axiosAdmin
        .post(`/api/admin/transaction/on-delivery/${payload.id}`, data)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },

    // get repayment transaction by product id from backend
    async getRepayment({ commit }, payload) {
      const qs = new URLSearchParams();
      qs.append('product_id', payload.productId);
      qs.append('kurs', payload.billRate);
      await axiosAdmin
        .get(`/api/admin/transaction/getPelunasan?${qs.toString()}`)
        .then((response) => {
          commit('setRepaymentList', response.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    transactions(state) {
      return state.transactions;
    },
    transactionDetail(state) {
      return state.transactionDetail;
    },
    transactionsPreOrder(state) {
      return state.transactionsPreOrder;
    },
    repaymentList(state) {
      return state.repaymentList;
    },
    preOrderDetail(state) {
      return state.preOrderDetail;
    },
  },
};
export default moduleTransaction;
