import axiosAdmin from "@/plugins/axiosAdmin";

const userModule = {
  state: () => ({
    listUser: [],
    user: "",
  }),
  mutations: {
    setListUser(state, payload) {
      state.listUser = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    // get all user data from backend
    async getUsers({ commit }, param) {
      await axiosAdmin
        .get(`/api/admin/users?${param}`)
        .then((resp) => {
          commit("setListUser", resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get user detail data from backend
    async getUser({ commit }, param) {
      await axiosAdmin
        .get(`/api/admin/users/${param}`)
        .then((resp) => {
          commit("setUser", resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // send new user data to backend
    async createUser(context, payload) {
      const formData = new FormData();
      formData.append("username", payload.username);
      formData.append("name", payload.name);
      formData.append("email", payload.email);
      formData.append("password", payload.password);
      formData.append("role", payload.role);
      formData.append("password_confirmation", payload.passwordConfirmation);

      await axiosAdmin.post("/api/admin/users", formData).then(() => {
        return Promise.resolve();
      });
    },
    // send deleted user uuid to backend
    async deleteUser(context, payload) {
      axiosAdmin
        .delete(`/api/admin/users/${payload}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // send updated user data to backend
    async updateUser(context, payload) {
      await axiosAdmin
        .put(`/api/admin/users/${payload.username}`, payload.data)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    listUser(state) {
      return state.listUser;
    },
    user(state) {
      return state.user;
    },
  },
};

export default userModule;
