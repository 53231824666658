import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{staticStyle:{"z-index":"40"},attrs:{"bottom":"","offset-y":"","left":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"mr-4"},'button',attrs,false),on),[(_vm.counter >= 1)?_c(VBadge,{attrs:{"color":"error","content":_vm.counter,"overlap":""}},[_c(VIcon,[_vm._v("mdi-bell")])],1):_c(VIcon,[_vm._v("mdi-bell")])],1)]}}])},[_c(VList,[_c(VSubheader,[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"text-h6 font-weight-medium"},[_vm._v("Notifikasi")]),_c('span',{class:{ 'primary--text': !_vm.isLoading },staticStyle:{"cursor":"pointer"},on:{"click":_vm.readAll}},[_c(VIcon,{attrs:{"color":_vm.isLoading ? '' : 'primary'}},[_vm._v("mdi-check-all")]),_vm._v(" Baca Semua"),(_vm.isLoading)?_c('span',[_vm._v("...")]):_vm._e()],1)])]),_c(VDivider),_vm._l((_vm.notifications),function(item,i){return _c(VListItem,{key:i,class:item.read_at ? '' : 'unread',on:{"click":function($event){return _vm.goToNotificationDetail(item.data.url, item.id)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-email-outline")])],1),_c(VListItemContent,{staticClass:"pl-2",staticStyle:{"max-width":"250px"}},[_c(VListItemTitle,[_vm._v(_vm._s(item.data.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.data.message))])],1),_c(VListItemActionText,[_vm._v(_vm._s(_vm.formatDateAgo(item.created_at)))])],1)}),_c('div',{staticClass:"pa-4"},[_c(VBtn,{attrs:{"color":"primary","block":"","link":"","to":"/notifications"}},[_vm._v("lihat semua notifikasi "),_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }