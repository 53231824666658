const appModule = {
  state: () => ({
    dialogError: false,
    dialogTs: false,
  }),
  mutations: {
    setDialogError(state, payload) {
      state.dialogError = payload;
    },
    // open transaction dialog
    setDialogTs(state, payload) {
      state.dialogTs = payload;
    },
  },
  getters: {
    dialogTs(state) {
      return state.dialogTs;
    },
  },
};

export default appModule;
