import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import moduleApp from "./modules/app";
import category from "./modules/category";
import moduleProductManagement from "./modules/product";
import snackbar from "./modules/snackbar";
import moduleTransaction from "./modules/transaction";
import moduleUser from "./modules/user";
import moduleAuth from "./modules/auth";
import roleModule from "./modules/role";
import notificationModule from "./modules/notification";
import currencyModule from "./modules/currency";
import moduleCustomer from "./modules/customer";
import moduleBank from "./modules/bank";
import modulePurchaseOrder from "./modules/purchaseOrder";
import moduleProvince from "./modules/province";
import moduleAbout from "./modules/about";
import moduleFaq from "./modules/faq";
import moduleShipping from "./modules/shipping";
import modulePayback from "./modules/payback";
import moduleFreight from "./modules/freight";
import moduleReport from "./modules/report";
const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    moduleApp,
    category,
    moduleProductManagement,
    snackbar,
    moduleTransaction,
    moduleUser,
    moduleAuth,
    roleModule,
    notificationModule,
    currencyModule,
    moduleCustomer,
    modulePurchaseOrder,
    moduleBank,
    moduleProvince,
    moduleAbout,
    moduleFaq,
    moduleShipping,
    modulePayback,
    moduleFreight,
    moduleReport,
  },
});
export default store;
