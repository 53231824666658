<template>
  <div
    v-if="showOverlay"
    class="white overlay-loading d-flex align-items-center"
  >
    <div class="ma-auto" style="width: 600px">
      <h1 class="text-h2 mb-6 primary--text text-center font-weight-medium">
        Live 4 Toys Admin
      </h1>
      <v-progress-linear
        color="primary"
        indeterminate
        reverse
      ></v-progress-linear>
      <p class="text-center mt-4 mb-0 grey--text text--darken-3">
        Loading... please wait
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";

export default defineComponent({
  setup() {},
  computed: {
    ...mapGetters(["showOverlay"]),
  },
});
</script>

<style lang="scss" scoped>
.overlay-loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
