<template>
  <!-- Dialog Menu Notification -->
  <v-menu
    style="z-index: 40"
    bottom
    offset-y
    left
    :close-on-content-click="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Button Notification with Counter -->
      <button class="mr-4" v-bind="attrs" v-on="on">
        <v-badge v-if="counter >= 1" color="error" :content="counter" overlap>
          <v-icon>mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell</v-icon>
      </button>
    </template>

    <!-- Feature Notification -->
    <v-list>
      <v-subheader>
        <div class="d-flex justify-space-between" style="width: 100%">
          <span class="text-h6 font-weight-medium">Notifikasi</span>
          <!-- Read All Notification -->
          <span
            style="cursor: pointer"
            :class="{ 'primary--text': !isLoading }"
            @click="readAll"
            ><v-icon :color="isLoading ? '' : 'primary'">mdi-check-all</v-icon>
            Baca Semua<span v-if="isLoading">...</span>
          </span>
        </div>
      </v-subheader>
      <v-divider></v-divider>

      <!-- List Notification with Route to Detail Notification Page -->
      <v-list-item
        v-for="(item, i) in notifications"
        :key="i"
        @click="goToNotificationDetail(item.data.url, item.id)"
        :class="item.read_at ? '' : 'unread'"
      >
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content style="max-width: 250px" class="pl-2">
          <v-list-item-title>{{ item.data.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.data.message }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>{{
          formatDateAgo(item.created_at)
        }}</v-list-item-action-text>
      </v-list-item>
      <!-- Button Route to View All Notification -->
      <div class="pa-4">
        <v-btn color="primary" block link to="/notifications"
          >lihat semua notifikasi <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
// Import Store, Library & Component
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import store from "@/store";
import moment from "moment";
import axiosAdmin from "@/plugins/axiosAdmin";

export default defineComponent({
  // Setup Data
  setup() {
    // loading
    const isLoading = ref(false);

    // Notifications Data from store
    const notifications = computed(() => {
      return store.getters.listNotificationComponent;
    });

    // Get Data Notifications
    async function getNotifications() {
      // Dispatch store
      await store.dispatch("getNotifications");
    }

    // Format Date Ago for Time Notifications
    function formatDateAgo(val) {
      return moment(val).startOf("second").fromNow();
    }

    // On Mounted
    onMounted(() => {
      // Get Notifications to Update Data
      getNotifications();
    });

    // Read All Notification
    function readAll() {
      // Loading to be True
      isLoading.value = true;
      // Dispatch store
      store
        .dispatch("readAllNotification")
        // Success
        .then(() => {
          // Get Data Notifications
          getNotifications();
        })
        // Error
        .catch(() => {
          // Commit store
          store.commit("setErrorSnack");
        })
        // Finally
        .finally(() => {
          // Loading to be False
          isLoading.value = false;
        });
    }

    // Return Data
    return {
      notifications,
      formatDateAgo,
      readAll,
      isLoading,
      getNotifications,
    };
  },

  // Methods
  methods: {
    // Route to Notification Detail
    async goToNotificationDetail(url, id) {
      // Suitable Route Path
      await axiosAdmin.get(`/api/admin/notification/${id}`);
      const path = url.substring(25, url.length);
      // Get Notifications to Update Data
      this.getNotifications();
      // Push to Route
      this.$router.push(path);
    },
  },

  // Computed
  computed: {
    // Counter Unread Notifications
    counter() {
      // Filter Data Notifications from state
      return store.state.notificationModule.listNotification.filter(function (
        unreadNotification
      ) {
        // Filter in "read_at === null"
        return unreadNotification.read_at === null;
      }).length;
    },
  },
});
</script>

<style scoped>
/* Style for Unread Notifications */
.unread {
  background-color: #e3f2fd;
}
</style>
