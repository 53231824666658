<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs" dividers="/"> </v-breadcrumbs>
    <section
      v-if="
        account.is_active == 1 &&
        permissions.includes('manage-report-transaction')
      "
      class="mx-4 pa-4 white"
    >
      <v-row>
        <v-col cols="4">
          <v-dialog
            ref="monthDialog"
            v-model="monthModal"
            :return-value.sync="date"
            persistent
            width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="showedDate"
                label="Pilih Bulan"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="monthModal = false">
                Batal
              </v-btn>
              <v-btn text color="primary" @click="changeDate(date)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="type"
            label="Pilih Tipe"
            prepend-inner-icon="mdi-filter"
            outlined
            single-line
            dense
            :disabled="isTypeSelectDisabled"
            :items="typeOptions"
            @change="fetchData"
          ></v-select>
        </v-col>
      </v-row>
      <apexchart
        ref="chart"
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </section>
    <section v-else class="mx-4 pa-4 white">
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="mb-2">Selamat Datang di Admin Life 4 Toys</h1>
          <h5>
            Tolong Aktifkan Peran dan Perizinan Anda untuk Mendapatkan Akses
          </h5>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axiosAdmin from "@/plugins/axiosAdmin";
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Beranda",
          disabled: true,
        },
      ],
      series: [
        {
          name: "Penjualan",
          data: [],
        },
        {
          name: "Uang",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        title: {
          text: `Grafik penjualan bulanan pada ${new Date().getFullYear()}`,
          align: "left",
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            seriesName: "Penjualan",
            opposite: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#26a0fc",
            },
            labels: {
              style: {
                colors: "#26a0fc",
              },
            },
            title: {
              text: "Total Penjualan",
              style: {
                color: "#26a0fc",
              },
            },
            max: (max) => {
              return max + 4;
            },
          },
          {
            seriesName: "Uang",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#4aebb4",
            },
            labels: {
              style: {
                colors: "#4aebb4",
              },
            },
            title: {
              text: "Total Uang (IDR)",
              style: {
                color: "#4aebb4",
              },
            },
          },
        ],
      },
      date: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
      showedDate: "",
      type: "Bulanan",
      monthModal: false,
      typeOptions: ["Harian", "Bulanan"],
      isTypeSelectDisabled: false,
    };
  },
  computed: {
    ...mapGetters(["account", "permissions"]),
  },
  methods: {
    fetchData() {
      if (
        this.account.is_active == 1 &&
        this.permissions.includes("manage-report-transaction")
      ) {
        const qs = new URLSearchParams();
        qs.append("type", this.type === "Bulanan" ? "monthly" : "daily");
        if (this.date) {
          qs.append("month", new Date(this.date).getMonth() + 1);
          qs.append("year", new Date(this.date).getFullYear());
        }
        axiosAdmin
          .get(`api/admin/report/transaction?${qs.toString()}`)
          .then((res) => {
            const salesData = [];
            const moneyData = [];
            const xAxis = [];
            const xAxisMonth = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "Mei",
              "Jun",
              "Jul",
              "Agu",
              "Sep",
              "Okt",
              "Nov",
              "Des",
            ];
            const { data } = res.data;
            data.map((item) => {
              salesData.push(item.total_count);
              moneyData.push(parseInt(item.total_idr.split(".").join("")));
              if (item.day) {
                xAxis.push(item.day);
              }
              if (item.month) {
                xAxis.push(xAxisMonth[item.month - 1]);
              }
            });
            this.$refs.chart.updateSeries([
              {
                name: "Penjualan",
                data: salesData,
              },
              {
                name: "Uang",
                data: moneyData,
              },
            ]);
            this.$refs.chart.updateOptions({
              xaxis: {
                categories: xAxis,
              },
            });
          });
      }
    },
    changeDate(date) {
      this.$refs.monthDialog.save(date);
      const month = new Date(this.date).getMonth() + 1;
      if (this.type === "Bulanan") {
        this.showedDate = new Date(this.date).getFullYear();
      }

      if (this.type === "Harian" && month < 10) {
        this.showedDate = `${new Date(this.date).getFullYear()}-0${
          new Date(this.date).getMonth() + 1
        }`;
      }
      if (this.type === "Harian" && month >= 10) {
        this.showedDate = `${new Date(this.date).getFullYear()}-${
          new Date(this.date).getMonth() + 1
        }`;
      }
      this.monthModal = false;
      this.fetchData();
    },
    getMonthName(month) {
      const name = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      return name[month];
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },
  mounted() {
    const month = new Date(this.date).getMonth() + 1;
    if (this.type === "Bulanan") {
      this.showedDate = new Date(this.date).getFullYear();
    }

    if (this.type === "Harian" && month < 10) {
      this.showedDate = `${new Date(this.date).getFullYear()}-0${
        new Date(this.date).getMonth() + 1
      }`;
    }
    if (this.type === "Harian" && month >= 10) {
      this.showedDate = `${new Date(this.date).getFullYear()}-${
        new Date(this.date).getMonth() + 1
      }`;
    }
    this.fetchData();
  },
  watch: {
    date(value) {
      if (value) {
        this.type = "Daily";
      }
    },
    type(value) {
      const selectedMonth = new Date(this.date).getMonth() + 1;
      const selectedYear = new Date(this.date).getFullYear();
      if (value === "Bulanan") {
        this.showedDate = selectedYear;
        this.$refs.chart.updateOptions({
          title: {
            text: `Grafik penjualan bulanan pada ${selectedYear}`,
          },
        });
      }
      if (value === "Harian") {
        if (selectedMonth < 10) {
          this.showedDate = `${selectedYear}-0${selectedMonth}`;
        }
        if (selectedMonth >= 10) {
          this.showedDate = `${selectedYear}-${selectedMonth}`;
        }
        this.$refs.chart.updateOptions({
          title: {
            text: `Grafik penjualan harian pada ${this.getMonthName(
              selectedMonth - 1
            )} ${selectedYear}`,
          },
        });
      }
    },
  },
};
</script>
