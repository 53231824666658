import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store/index";
import jwtDecode from "jwt-decode";
// import axiosAdmin from "@/plugins/axiosAdmin";

Vue.use(VueRouter);

// Get local storage data account
const accountActivated = localStorage.getItem("accountActivated");
const accountPermission = JSON.parse(localStorage.getItem("accountPermission"));
// data permissions
const permissions = [];

// Mapping PermissionsData if Get Data Account from Local Storage
if (accountActivated && accountPermission) {
  const permissionsData = accountPermission.map((permission) => {
    return permission.name;
  });
  // Push Permissions Data to Permissions
  permissions.push(...permissionsData);
}

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
  },
  {
    path: "/profile",
    name: "ProfileView",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
  },
  {
    path: "/main-category",
    name: "MainCategory",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "MainCategory" */ "../views/categories/MainCategory.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-category")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/sub-category",
    name: "SubCategory",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "SubCategory" */ "../views/categories/SubCategory.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-category")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/category",
    name: "Category",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "ListCategory" */ "../views/categories/ListCategory.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-category")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/brand",
    name: "Brand",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Brand" */ "../views/product/BrandManagement.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-brand")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/add-product-image/:uuid",
    name: "AddProductImage",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AddProduct" */ "../views/product/AddProductImage.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/product/pre-order",
    name: "ProductPreOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () => import("../views/product/pre-order/ListProduct"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/product/pre-order/add-product",
    name: "AddProductPreOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () => import("../views/product/pre-order/AddProduct"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/edit-product/:uuid",
    name: "EditProduct",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () => import("../views/product/EditProduct"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  // {
  //   path: "/product/:id/pre-order",
  //   name: "ProductDetail",
  //   meta: { nav: () => import("../components/shared/TheNavigation.vue") },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "ProductDetailPreOrder" */ "../views/product/pre-order/ProductDetail.vue"
  //     ),
  // },
  {
    path: "/product/ready-stock/add-product",
    name: "AddProductReadyStock",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AddProduct" */ "../views/product/ready-stock/AddProduct"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/product/ready-stock",
    name: "ProductReadyStock",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "ListProduct" */ "../views/product/ready-stock/ListProduct"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "ProductDetail" */ "../views/product/ProductDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/edit-product-image/:uuid",
    name: "EditProductImage",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AddProduct" */ "../views/product/EditProductImage.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-product")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/carousel",
    name: "Carousel",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "Carousel" */ "../views/CarouselView.vue"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-carousel")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/about",
    name: "About",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
          // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-about")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/faq",
    name: "Faq",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/FaqView.vue"),
          // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-faq")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/*webpackChunkName: "Login" */ "../views/LoginView.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /*webpackChunkName: "ForgotPassword" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/change-password",
    name: "NewPassword",
    component: () =>
      import(
        /*webpackChunkName: "ForgotPassword" */ "../views/NewPassword.vue"
      ),
  },

  // START TRANSACTION ROUTE
  {
    path: "/transactions/ready-stock",
    name: "TransactionReadyStock",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Transaksi" */ "../views/transactions/ready-stock/ListTransactions.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-ready-stock")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/transactions/pre-order",
    name: "TransactionPreOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Transaksi" */ "../views/transactions/pre-order/TransactionsPreOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-pre-order")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/transactions/pre-order/:productId",
    name: "ProductPreOrderDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Transaksi" */ "../views/transactions/pre-order/ProductPreOrderDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-pre-order")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/transaction/:uuid",
    name: "TransactionDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "TransactionDetail" */ "../views/transactions/TransactionDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        (accountActivated == 1 && permissions.includes("manage-pre-order")) ||
        permissions.includes("manage-ready-stock")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/shipping-cost",
    name: "ShippingCost",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "ShippingCost" */ "../views/shipping/ShippingCost.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-shipping-cost")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  // END TRANSACTION ROUTE
  {
    path: "/add-user",
    name: "AddUser",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "AddUser" */ "../views/user/AddUser.vue"),
  },
  {
    path: "/users",
    name: "ListUser",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "ListUser" */ "../views/user/ListUser.vue"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-user")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/user/:uuid",
    name: "UserDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "UserDetail" */ "../views/user/UserDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-user")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/customers",
    name: "ListCustomers",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "ListCustomer" */ "../views/customer/ListCustomer.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-customer")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/customer/:uuid",
    name: "CustomerDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "CustomerDetail" */ "../views/customer/CustomerDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-customer")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/customer/:id/transactions",
    name: "DetailHistoryTransaction",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "CustomerTransactionHistory" */ "../views/customer/CustomerTransactionHistory.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-customer")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/customer/:id/transactions/:uuid",
    name: "CustomerDetailTransaction",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "CustomerDetailTransaction" */ "../views/customer/CustomerDetailTransaction.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-customer")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/voucher",
    name: "Voucher",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Voucher" */ "../views/voucher/IndexVoucher.vue"
      ),
  },
  {
    path: "/voucher/:uuid",
    name: "VoucherDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "VoucherDetail" */ "../views/voucher/VoucherDetail.vue"
      ),
  },
  {
    path: "/discount",
    name: "Discount",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Discount" */ "../views/discount/IndexDiscount.vue"
      ),
  },
  {
    path: "/discount/:uuid",
    name: "DiscountDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "DiscountDetail" */ "../views/discount/DiscountDetail.vue"
      ),
  },
  {
    path: "/role",
    name: "Role",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "ListRole" */ "../views/role/ListRole.vue"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-role")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },

  {
    path: "/notifications",
    name: "AllNotification",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AllNotification" */ "../views/notification/ListNotification.vue"
      ),
  },
  // {
  //   path: "/report",
  //   name: "Report",
  //   meta: { nav: () => import("../components/shared/TheNavigation.vue") },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "Report" */ "../views/report/ReportTransaction.vue"
  //     ),
  // },
  // START FINANCE route
  {
    path: "/bank",
    name: "Bank",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "Bank" */ "../views/settings/Bank.vue"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-bank")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/currency",
    name: "SettingCurrency",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "Currency" */ "../views/SettingCurrency.vue"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-currency")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/payback",
    name: "Payback",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(/*webpackChunkName: "Currency" */ "../views/payback/PayBack.vue"),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-payback")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/payback/form",
    name: "AddPayback",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/payback/FormPayBack.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-payback")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/payback/form/:id",
    name: "EditPayBack",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/payback/FormPayBack.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-payback")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/payback/:id",
    name: "PayBackDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/payback/PayBackDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-payback")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/list",
    name: "ListPurchaseOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/ListPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-purchase-order")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/add",
    name: "AddPurchaseOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/AddPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-purchase-order")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  // {
  //   path: '/purchase-order/edit/:id',
  //   name: 'PurchaseOrder',
  //   meta: { nav: () => import('../components/shared/TheNavigation.vue') },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "Currency" */ '../views/purchase-order/AddPurchaseOrder.vue'
  //     ),
  // },
  {
    path: "/purchase-order/add-freight",
    name: "FreightPurchaseOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/FreightPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-freight")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/edit-freight/:id",
    name: "EditFreight",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/FreightPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-freight")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/freight/:id",
    name: "FreightDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "FreightDetail" */ "../views/purchase-order/FreightDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-freight")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/list-freight",
    name: "ListFreight",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "ListFreight" */ "../views/purchase-order/ListFreight.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (accountActivated == 1 && permissions.includes("manage-freight")) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/link",
    name: "LinkProductCustomer",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/LinkProductCustomer.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-product-customer")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/link/notification",
    name: "LinkProductCustomerNotification",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/LinkProductCustomerNotification.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-product-customer")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/:id",
    name: "DetailPurchaseOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/DetailPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-purchase-order")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/:id/edit",
    name: "EditPurchaseOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/EditPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-purchase-order")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/:id/payment",
    name: "DetailPaymentPurchaseOrder",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "Currency" */ "../views/purchase-order/DetailPaymentPurchaseOrder.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-purchase-order")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/purchase-order/:id/payment/add",
    name: "AddPaymentDetail",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AddPaymentDetail" */ "../views/purchase-order/AddPaymentDetail.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-purchase-order")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  // {
  //   path: "/report/transaction",
  //   name: "ReportTransaction",
  //   meta: { nav: () => import("../components/shared/TheNavigation.vue") },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "AddPaymentDetail" */ "../views/report/ReportTransaction.vue"
  //     ),
  // },
  // {
  //   path: "/report/order-to-factory",
  //   name: "ReportOrderFactory",
  //   meta: { nav: () => import("../components/shared/TheNavigation.vue") },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "AddPaymentDetail" */ "../views/report/ReportOrderFactory.vue"
  //     ),
  // },
  // {
  //   path: "/report/all",
  //   name: "ReportAll",
  //   meta: { nav: () => import("../components/shared/TheNavigation.vue") },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "AddPaymentDetail" */ "../views/report/ReportAll.vue"
  //     ),
  // },
  {
    path: "/report/purchase",
    name: "ReportPurchase",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AddPaymentDetail" */ "../views/report/ReportPurchase.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-report-purchase")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  {
    path: "/report/sales&customer",
    name: "ReportSales&Customer",
    meta: { nav: () => import("../components/shared/TheNavigation.vue") },
    component: () =>
      import(
        /*webpackChunkName: "AddPaymentDetail" */ "../views/report/ReportSales&Customer.vue"
      ),
    // Route Guard
    beforeEnter: (to, from, next) => {
      if (
        accountActivated == 1 &&
        permissions.includes("manage-report-sales-and-customer")
      ) {
        next(); // Take you to path
      } else {
        // If does not have activated role & permission, redirect back to the home page
        next({ name: "home" });
      }
    },
  },
  // {
  //   path: '/purchase-order/:id/payment-old/add',
  //   name: 'PaymentPurchaseOrder',
  //   meta: { nav: () => import('../components/shared/TheNavigation.vue') },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "AddPaymentDetail" */ '../views/purchase-order/PaymentPurchaseOrder.vue'
  //     ),
  // },
  // {
  //   path: '/purchase-order/:id/freight',
  //   name: 'DetailFreightPurchaseOrder',
  //   meta: { nav: () => import('../components/shared/TheNavigation.vue') },
  //   component: () =>
  //     import(
  //       /*webpackChunkName: "Currency" */ '../views/purchase-order/DetailFreightPurchaseOrder.vue'
  //     ),
  // },
  // END FINANCE ROUTE
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("setOverlayLoading", true);
  const token = localStorage.getItem("l4t-token");
  if (token === null) {
    if (
      to.path !== "/login" &&
      to.path !== "/forgot-password" &&
      to.path !== "/change-password"
    ) {
      next({ name: "Login" });
      store.commit("setOverlayLoading", false);
    } else {
      if (
        to.path === "/change-password" &&
        (!to.query.token || !to.query.username)
      ) {
        next({ name: "Login" });
        store.commit("setOverlayLoading", false);
      } else {
        next();
        store.commit("setOverlayLoading", false);
      }
    }
  } else {
    const user = jwtDecode(token);
    if (user.exp * 1000 - Date.now() < 0) {
      localStorage.removeItem("l4t-token");
      next({ name: "/login" });
    } else {
      store
        .dispatch("getAccount")
        .then(() => {
          store.dispatch("getNotifications");
          next();
        })
        .catch(() => {
          if (to.path !== "/login") {
            next({ name: "Login" });
          }
        })
        .finally(() => {
          store.commit("setOverlayLoading", false);
        });
    }
  }

  // if (!token) {
  //   next({ path: "/login" });
  //   store.commit("setOverlayLoading", false);
  // }
  // else if (token) {
  //   axiosAdmin
  //     .get("/api/admin/me")
  //     .then(() => {
  //       next();
  //       store.commit("setOverlayLoading", false);
  //     })
  //     .catch(() => {
  //       router.push("/login");
  //       store.commit("setOverlayLoading", false);
  //     });
  // } else {
  //   next();
  //   store.commit("setOverlayLoading", false);
  // }
});

export default router;
