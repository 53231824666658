import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"dividers":"/"}}),(
      _vm.account.is_active == 1 &&
      _vm.permissions.includes('manage-report-transaction')
    )?_c('section',{staticClass:"mx-4 pa-4 white"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VDialog,{ref:"monthDialog",attrs:{"return-value":_vm.date,"persistent":"","width":"300px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Pilih Bulan","prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","outlined":""},model:{value:(_vm.showedDate),callback:function ($$v) {_vm.showedDate=$$v},expression:"showedDate"}},'v-text-field',attrs,false),on))]}}],null,false,2484064590),model:{value:(_vm.monthModal),callback:function ($$v) {_vm.monthModal=$$v},expression:"monthModal"}},[_c(VDatePicker,{attrs:{"type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.monthModal = false}}},[_vm._v(" Batal ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.changeDate(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"label":"Pilih Tipe","prepend-inner-icon":"mdi-filter","outlined":"","single-line":"","dense":"","disabled":_vm.isTypeSelectDisabled,"items":_vm.typeOptions},on:{"change":_vm.fetchData},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('apexchart',{ref:"chart",attrs:{"type":"line","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1):_c('section',{staticClass:"mx-4 pa-4 white"},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"mb-2"},[_vm._v("Selamat Datang di Admin Life 4 Toys")]),_c('h5',[_vm._v(" Tolong Aktifkan Peran dan Perizinan Anda untuk Mendapatkan Akses ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }