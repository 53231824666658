import axiosAdmin from "@/plugins/axiosAdmin";

const moduleFaq = {
  state: () => ({
    listFaq: [],
  }),
  mutations: {
    addFaq(state, payload) {
      state.mainFaq = payload;
    },
    commitListFaq(state, payload) {
      state.listFaq = payload;
    },
  },
  actions: {
    // FAQ
    // send new faq data backend
    async addFaq(context, payload) {
      await axiosAdmin
        .post("/api/admin/faq/add", {
          question: payload.question,
          answere: payload.answere,
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // get list faq data from backend
    async getListFaq({ commit }, keyword) {
      const qs = new URLSearchParams();
      if (keyword) {
        qs.append("q", keyword);
      }
      await axiosAdmin
        .get(`/api/admin/faq?${qs.toString()}`)
        .then((resp) => {
          const listFaq = resp.data.data;
          commit("commitListFaq", listFaq);
          return Promise.resolve();
        });
    },
    // send updated faq data to backend
    async editFaq(context, payload) {
      await axiosAdmin
        .put("/api/admin/faq/update/" + payload.id, {
          question: payload.question,
          answere: payload.answere,
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // send deleted faq uuid to backend
    async deleteFaq(context, payload) {
      await axiosAdmin
        .delete("/api/admin/faq/delete/" + payload.id)
        .then((resp) => {
          return Promise.resolve(resp);
        });
    },

  },
  getters: {
    listFaq(state) {
      return state.listFaq;
    },
  },
};

export default moduleFaq;
