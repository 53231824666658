import axiosAdmin from '../../plugins/axiosAdmin';
const modulePurchaseOrder = {
  state() {
    return {
      purchaseOrders: [],
      purchaseOrder: {},
      listProductFreight: [],
    };
  },
  mutations: {
    setPurchaseOrders(state, payload) {
      state.purchaseOrders = payload;
    },
    setPurchaseOrder(state, payload) {
      state.purchaseOrder = payload;
    },
    setListProductFreight(state, payload) {
      state.listProductFreight = payload;
    },
  },
  actions: {
    // get all pruchase order data from backend
    async getPurchaseOrders({ commit }, query) {
      return axiosAdmin
        .get(`/api/admin/purchase-order${query ? `?q=${query}` : ''}`)
        .then((res) => {
          commit('setPurchaseOrders', res.data.data);
        });
    },
    // get single purchase order data from backend
    async getPurchaseOrder({ commit }, id) {
      return axiosAdmin.get(`/api/admin/purchase-order/${id}`).then((res) => {
        commit('setPurchaseOrder', res.data.data);
      });
    },
    // send new purchase order data to backend
    async postPurchaseOrder(_, payload) {
      return axiosAdmin.post('/api/admin/purchase-order', payload);
    },
    // send updated purchase order data to backend
    async putPurchaseOrder(_, payload) {
      return axiosAdmin.put(
        `/api/admin/purchase-order/${payload.id}`,
        payload.data
      );
    },
    // send new payment of purchase order data to backend
    async paymentPo(_, payload) {
      return axiosAdmin.post(
        `/api/admin/purchase-order/payment/${payload.id}`,
        payload.data
      );
    },
    // send freight of purchase order data to backend
    async freightPo(_, payload) {
      return axiosAdmin.post(
        `/api/admin/purchase-order/freight/${payload.id}`,
        payload.data
      );
    },
    // link ready product to customer who order it
    async linkPo(_, payload) {
      return axiosAdmin.post(`/api/admin/purchase-order/transaction`, payload);
    },
    // send cancelled purchase order id to backend
    async cancelPo(_, id) {
      return axiosAdmin.post(`/api/admin/purchase-order/failed/${id}`);
    },
    // send deleted purchase order id to backend
    async deletePo(_, id) {
      return axiosAdmin.delete(`/api/admin/purchase-order/${id}`);
    },
    // send deleted detail puchase order id to backend
    async deleteDetailProduct(_, id) {
      return axiosAdmin.post(`/api/admin/purchase-order/delete-detail/${id}`);
    },
    // send new freight of purchase order data to backend
    async storeFreight(_, payload) {
      return axiosAdmin.post('/api/admin/freight', payload);
    },
    // get product that already arrive on staging
    async getProductFreight({ commit }) {
      return axiosAdmin.get('/api/admin/products/customer').then((response) => {
        commit('setListProductFreight', response.data.data);
      });
    },
  },
  getters: {
    purchaseOrders(state) {
      return state.purchaseOrders;
    },
    purchaseOrder(state) {
      return state.purchaseOrder;
    },
    listProductFreight(state) {
      return state.listProductFreight;
    },
  },
};

export default modulePurchaseOrder;
