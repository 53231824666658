import axiosAdmin from "@/plugins/axiosAdmin";

const bankModule = {
  state: () => ({
    listBank: [],
    allBank: [
      "BNI",
      "BRI",
      "CIMB NIAGA",
      "BCA",
      "MANDIRI",
      "BPD",
      "BANK PERMATA",
    ],
  }),
  mutations: {
    setListBank(state, payload) {
      state.listBank = payload;
    },
  },
  actions: {
    // get list bank data
    async getListBank({ commit }) {
      await axiosAdmin
        .get("/api/bank")
        .then((resp) => {
          commit("setListBank", resp.data.data);
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // send new bank data to backend
    async storeBank(context, payload) {
      await axiosAdmin
        .post("/api/bank", payload)
        .then(() => {
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // send updated bank data to backend
    async putBank(context, payload) {
      await axiosAdmin
        .put(`/api/bank/${payload.id}`, payload.data)
        .then(() => {
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // send deleted bank data to backend
    async deleteBank(context, payload) {
      await axiosAdmin
        .delete(`/api/bank/${payload}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    listBank(state) {
      return state.listBank.filter((item) => item.is_active === 1);
    },
    listBankAllStatus(state) {
      return state.listBank;
    },
    allBank(state) {
      return state.allBank.sort();
    },
  },
};

export default bankModule;
