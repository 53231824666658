import axiosAdmin from "@/plugins/axiosAdmin";

const notificationModule = {
  state: () => ({
    listNotification: [],
    notificationID: [],
  }),
  mutations: {
    setNotifications(state, payload) {
      state.listNotification = payload;
      if (Array.isArray(payload)) {
        const id = [];
        for (let i = 0; i < payload.length; i++) {
          id.push(payload[i].id);
        }
        state.notificationID = id;
      }
    },
  },
  actions: {
    // get notification data from backend
    async getNotifications({ commit }) {
      await axiosAdmin
        .get("/api/admin/notification")
        .then((resp) => {
          commit("setNotifications", resp.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get all read notification data from backend
    async readAllNotification() {
      await axiosAdmin
        .get("/api/admin/notification/mark-read-all")
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    listNotification(state) {
      return state.listNotification;
    },
    idNotifications(state) {
      return state.notificationID;
    },
    listNotificationComponent(state) {
      return state.listNotification.slice(0, 5);
    },
  },
};
export default notificationModule;
