import axiosAdmin from "@/plugins/axiosAdmin";

const paybackModule = {
  state: () => ({
    listPayback: [],
    paybackDetail: {},
    productsPayback: [],
  }),
  mutations: {
    setListPayback(state, payload) {
      state.listPayback = payload.sort((a, b) => a.created_at - b.created_at);
    },
    setPaybackDetail(state, payload) {
      state.paybackDetail = payload;
    },
    setPaybackProducts(state, payload) {
      state.productsPayback = payload;
    },
    setPaybackProductDetail(state) {
      state.p;
    },
  },
  actions: {
    // send new payback data to backend
    async storePayback(_, payload) {
      try {
        const response = await axiosAdmin.post("/api/admin/payback", payload);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // get all product data that have dp paid transaction from backend
    async getProductsPayback({ commit }) {
      try {
        const response = await axiosAdmin.get("/api/admin/products/payback");
        commit("setPaybackProducts", response.data.data);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // get payback detail by product id from backend
    async getProductPaybackDetail(_, productId) {
      try {
        const response = await axiosAdmin.get(
          `/api/admin/customer/payback/${productId}`
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // send updated payback data to backend
    async putPayback(_, payload) {
      try {
        const response = await axiosAdmin.put(
          `/api/admin/payback/${payload.id}`,
          payload.data
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // get list payback data from backend
    async getListPayback({ commit }) {
      await axiosAdmin
        .get("/api/admin/payback")
        .then((resp) => {
          commit("setListPayback", resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get payback data from backend
    async getPaybackDetail({ commit }, id) {
      await axiosAdmin
        .get(`/api/admin/payback/${id}`)
        .then((resp) => {
          commit("setPaybackDetail", resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // send payback confirmation status to backend
    async confirmPayback(context, id) {
      await axiosAdmin
        .post(`/api/admin/payback/confirmation/${id}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // send deleted payback id to backend
    async deletePayback(context, id) {
      await axiosAdmin
        .delete(`/api/admin/payback/${id}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    listPayback(state) {
      return state.listPayback;
    },
    payback(state) {
      return state.paybackDetail;
    },
    productsPayback(state) {
      return state.productsPayback;
    },
  },
};

export default paybackModule;
