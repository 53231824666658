import axiosAdmin from "@/plugins/axiosAdmin";
const moduleReport = {
  state: () => ({
    reportsPurchase: [],
    reportsSalesAndCustomer: [],
  }),
  mutations: {
    setReportsPurchase(state, payload) {
      state.reportsPurchase = payload;
    },
    setReportsSalesAndCustomer(state, payload) {
      state.reportsSalesAndCustomer = payload;
    },
  },
  actions: {
    // get report purchase data from backend
    async getReportsPurchase({ commit }, param) {
      await axiosAdmin
        .get(`/api/admin/report/purchase?${param}`)
        .then((resp) => {
          commit("setReportsPurchase", resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get report sales and customer data from backend
    async getReportsSalesAndCustomer({ commit }, param) {
      await axiosAdmin
        .get(`/api/admin/report/salesandcustomer?${param}`)
        .then((resp) => {
          commit("setReportsSalesAndCustomer", resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    reportsPurchase(state) {
      return state.reportsPurchase;
    },
    reportsSalesAndCustomer(state) {
      return state.reportsSalesAndCustomer;
    },
  },
};
export default moduleReport;
