import router from "@/router/index";
import axios from "axios";
import jwt_decode from "jwt-decode";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  // baseURL: "https://8231-111-68-29-23.ngrok-free.app/",
  // baseURL: "http://194.31.53.214:2048/",
});

console.log(process.env.VUE_APP_BASE_URL_API, "vuex");
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("l4t-token");
  if (token) {
    const user = jwt_decode(token);
    if (user.exp * 1000 - Date.now() < 0) {
      localStorage.removeItem("l4t-token");
      router.push("/login");
    } else {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  } else if (
    config.url !== "/api/auth/login" &&
    config.url !== "/api/auth/password/forgot-password"
  ) {
    router.push("/login");
  }
  return config;
});
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const err = error;
    /* auto logout if response is unauthorized */
    if (err.response?.status === 401) {
      router.push("/login");
    }
    return Promise.reject(error);
  }
);
export default instance;
