import axiosAdmin from "@/plugins/axiosAdmin";

const moduleAbout = {
  state: () => ({
    listAbout: [],
  }),
  mutations: {
    addAbout(state, payload) {
      state.mainAbout = payload;
    },
    commitListAbout(state, payload) {
      state.listAbout = payload;
    },
  },
  actions: {
    // About
    // send new about data backend
    async addAbout(context, payload) {
      let data = new FormData();
      data.append("title", payload.title);
      data.append("description", payload.description);
      data.append("image", payload.image);
      await axiosAdmin
        .post("/api/admin/about/add", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // get list about data from backend
    async getListAbout({ commit }, keyword) {
      const qs = new URLSearchParams();
      if (keyword) {
        qs.append("q", keyword);
      }
      await axiosAdmin
        .get(`/api/admin/about?${qs.toString()}`)
        .then((resp) => {
          const listAbout = resp.data.data;
          commit("commitListAbout", listAbout);
          return Promise.resolve();
        });
    },
    // send updated about data to backend
    async editAbout(context, payload) {
      let data = new FormData();
      data.append("title", payload.title);
      data.append("description", payload.description);
      if (typeof payload.image === "object" ) {
        data.append("image", payload.image);
      }
    //  console.log(typeof payload.image);

      data.append("_method", 'PUT');
      await axiosAdmin
        .post("/api/admin/about/update/" + payload.id, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // send deleted about uuid to backend
    async deleteAbout(context, payload) {
      await axiosAdmin
        .delete("/api/admin/about/delete/" + payload.id)
        .then((resp) => {
          return Promise.resolve(resp);
        });
    },

  },
  getters: {
    listAbout(state) {
      return state.listAbout;
    },
  },
};

export default moduleAbout;
