import axiosAdmin from "@/plugins/axiosAdmin";

const currencyModule = {
  state: () => ({
    currencies: [],
  }),
  mutations: {
    setCurrencies(state, payload) {
      state.currencies = payload;
    },
  },
  actions: {
    // get currencies data from backend
    async getCurrencies({ commit }) {
      const res = await axiosAdmin.get("/api/currency");
      commit("setCurrencies", res.data.data);
    },
    // send new currency data to backend
    async storeCurrency(_, payload) {
      const res = await axiosAdmin.post("/api/currency", payload);
      return Promise.resolve(res.data.data);
    },
    // send updated currency data to backend
    async updateCurrency(_, payload) {
      return axiosAdmin.put("/api/currency/" + payload.id, payload);
    },
    // send deleted currency id to backend
    async deleteCurrency(_, id) {
      return axiosAdmin.delete("/api/currency/" + id);
    },
  },
  getters: {
    currencies(state) {
      return state.currencies;
    },
  },
};
export default currencyModule;
