import axiosAdmin from "../../plugins/axiosAdmin";
const moduleProvince = {
  state() {
    return {
      provinces: [],
    };
  },
  mutations: {
    setProvinces(state, payload) {
      state.provinces = payload;
    },
  },
  actions: {
    // get all provinces data from backend
    async getProvinces({ commit }) {
      return axiosAdmin.get("/api/province").then((res) => {
        commit("setProvinces", res.data);
      });
    },
  },
  getters: {
    provinces(state) {
      return state.provinces;
    },
  },
};

export default moduleProvince;
