import axiosAdmin from '@/plugins/axiosAdmin';
const moduleProductManagement = {
  state: () => {
    return {
      brands: [],
      products: [],
      product: {},
      productUuid: '',
    };
  },
  mutations: {
    setBrands(state, payload) {
      state.brands = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setProductUuid(state, payload) {
      state.productUuid = payload;
    },
  },
  actions: {
    // brand method
    // get all brand data from backend
    async getBrands({ commit }) {
      await axiosAdmin.get('/api/admin/brand').then((resp) => {
        const data = resp.data.data;
        commit('setBrands', data);
        return Promise.resolve();
      });
    },
    // send new brand data to backend
    async addBrand(context, payload) {
      await axiosAdmin.post('/api/admin/brand', payload).then(() => {
        return Promise.resolve();
      });
    },
    // send updated brand data to backend
    async editBrand(context, payload) {
      const name = payload.name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      const data = new FormData();
      data.append('_method', 'PUT');
      data.append('name', name);
      data.append('code', payload.code.toUpperCase());
      data.append('description', payload.description);
      if (payload.photo instanceof File) {
        data.append('photo', payload.photo);
      }
      await axiosAdmin
        .post(`/api/admin/brand/${payload.uuid}`, data)
        .then(() => {
          return Promise.resolve();
        });
    },
    // send deleted brand uuid to backend
    async deleteBrand(context, payload) {
      await axiosAdmin.delete('/api/admin/brand/' + payload.uuid).then(() => {
        return Promise.resolve();
      });
    },
    // product method
    // get all product data from backend
    async getProducts({ commit }, payload) {
      const qs = new URLSearchParams();
      if (payload.category) {
        payload.category.forEach((category) => {
          qs.append('category[]', category);
        });
      }
      if (payload.brands) {
        payload.brands.forEach((brand) => {
          qs.append('brand[]', brand);
        });
      }
      if (payload.availability) {
        qs.append('availability', payload.availability);
      }
      if (payload.q) {
        qs.append('q', payload.q);
      }
      await axiosAdmin
        .get('/api/products/get-list?' + qs.toString())
        .then((resp) => {
          commit('setProducts', resp.data.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get single product data from backend
    async getProduct({ commit }, payload) {
      await axiosAdmin.get('/api/admin/products/' + payload).then((resp) => {
        commit('setProduct', resp.data.data);
        return Promise.resolve(resp.data.data);
      });
    },
    // send updated product data to backend
    async updateProduct(_, payload) {
      return axiosAdmin.put(
        '/api/admin/products/' + payload.uuid,
        payload.data
      );
    },
    // send deleted product uuid to backend
    async deleteProduct(context, payload) {
      await axiosAdmin.delete('/api/admin/products/' + payload).then(() => {
        return Promise.resolve();
      });
    },
  },
  getters: {
    brands(state) {
      return state.brands;
    },
    optionsBrand(state) {
      return state.brands.map((item) => {
        return { text: item.name, value: item.id };
      });
    },
    products(state) {
      return state.products;
    },
    productsPreOrder(state) {
      // filter list products dengan availability "pre-order"
      return state.products.filter((item) => item.availability === 'pre-order');
    },
    product(state) {
      return state.product;
    },
    productUuid(state) {
      return state.productUuid;
    },
  },
};
export default moduleProductManagement;
