import axiosAdmin from "@/plugins/axiosAdmin";

const authModule = {
  state: () => ({
    account: {},
  }),
  mutations: {
    setAccount(state, payload) {
      state.account = payload;
    },
  },
  actions: {
    // get current user login
    async getAccount({ commit }) {
      axiosAdmin
        .get("/api/admin/me")
        .then((resp) => {
          commit("setAccount", resp.data.data);
          // encrypt local storage data account activated & JSON stingify
          const accountActivated = resp.data.data.is_active;
          localStorage.setItem("accountActivated", accountActivated);
          // encrypt local storage data account activated & JSON stingify
          const accountPermission = resp.data.data.permission;
          localStorage.setItem(
            "accountPermission",
            JSON.stringify(accountPermission)
          );
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    account(state) {
      return state.account;
    },
    permissions(state) {
      if (state.account.permission) {
        return state.account.permission.map((permission) => {
          return permission.name;
        });
      }
      return [];
    },
  },
};
export default authModule;
