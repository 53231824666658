import axiosAdmin from '@/plugins/axiosAdmin';
const freightModule = {
  state: () => ({
    listFreight: [],
    freight: null,
    listProductPO: [],
  }),
  mutations: {
    setListFreight(state, payload) {
      state.listFreight = payload;
    },
    setFreight(state, payload) {
      state.freight = payload;
    },
    setListProductPO(state, payload) {
      state.listProductPO = payload;
    },
  },
  actions: {
    // get list freight data from backend
    async getListFreight({ commit }) {
      try {
        const response = await axiosAdmin.get('/api/admin/freight');
        commit('setListFreight', response.data.data);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // send confirmation freight status to backend
    async confirmFreight(_, payload) {
      try {
        const response = await axiosAdmin.post(
          `/api/admin/freight/confirmation/${payload.id}`
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // get single freight data from backend
    async getFreight({ commit }, id) {
      try {
        const response = await axiosAdmin.get(`/api/admin/freight/${id}`);
        commit('setFreight', response.data.data);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // send updated freight data to backend
    async putFreight(_, payload) {
      try {
        const response = await axiosAdmin.put(
          `/api/admin/freight/${payload.id}`,
          payload.data
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // get product that already listed on purchase order from backend
    async getProductPO({ commit }) {
      try {
        const response = await axiosAdmin.get('/api/admin/products/freight');
        commit('setListProductPO', response.data.data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    },
    // delete freight
    async deleteFreight(_, id) {
      try {
        await axiosAdmin.delete(`/api/admin/freight/${id}`);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    },
  },
  getters: {
    listFreight(state) {
      return state.listFreight;
    },
    freight(state) {
      return state.freight;
    },
    listProductPO(state) {
      return state.listProductPO;
    },
  },
};
export default freightModule;
