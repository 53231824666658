import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCompositionAPI from "@vue/composition-api";
import vuetify from "./plugins/vuetify";
import VueEditor from "vue2-editor";
import store from "./store";
import "./assets/scss/index.scss";
import formatter from "@/mixin/formatter";
import imageViewer from "vue-image-viewer";
Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(VueEditor);
Vue.use(imageViewer);
Vue.mixin(formatter);
Vue.prototype.$errSnack = { show: false, message: "Something went wrong." };
Vue.prototype.$successSnack = { show: false, message: "Successfully" };
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
