const snackbar = {
  state: () => {
    return {
      success: false,
      successMessage: "",
      error: false,
      errorMessage: "Terjadi kesalahan.",
      showLoadingOverlay: false,
    };
  },
  mutations: {
    setSuccessSnack(state, message) {
      state.successMessage = message;
      state.success = true;
    },
    setErrorSnack(state, message) {
      if (message) {
        state.errorMessage = message;
      }
      state.error = true;
    },
    setOverlayLoading(state, value) {
      state.showLoadingOverlay = value;
    },
  },
  getters: {
    snackSuccess(state) {
      return state.success;
    },
    snackSuccessMessage(state) {
      return state.successMessage;
    },
    snackError(state) {
      return state.error;
    },
    snackErrorMessage(state) {
      return state.errorMessage;
    },
    showOverlay(state) {
      return state.showLoadingOverlay;
    },
  },
};

export default snackbar;
