<template>
  <header>
    <v-navigation-drawer v-model="isNav" app stateless>
      <v-list-item style="height: 64px; padding: 24px">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <img
              src="@/assets/logo/statue4heroes.png"
              style="width: 100%"
              alt=""
            />
          </v-list-item-title>
          <!-- <v-list-item-subtitle class="text-center">
            Admin</v-list-item-subtitle
          > -->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list class="pb-6" dense nav>
        <v-subheader>Beranda</v-subheader>
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Beranda </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div v-if="account.is_active == 1">
          <!-- transactions -->
          <v-subheader
            v-if="
              permissions.includes('manage-ready-stock') ||
              permissions.includes('manage-pre-order')
            "
            >Transaksi</v-subheader
          >
          <!-- transactions -->
          <!-- <v-list-item
          v-if="permissions.includes('transaction')"
          to="/transactions"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-note-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Transaksi</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
          <v-list-item
            v-if="permissions.includes('manage-ready-stock')"
            to="/transactions/ready-stock"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-book-check-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Ready Stock</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="permissions.includes('manage-pre-order')"
            to="/transactions/pre-order"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-book-clock-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Pre Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- end transactions -->
          <v-subheader
            v-if="
              permissions.includes('manage-user') ||
              permissions.includes('manage-role') ||
              permissions.includes('manage-customer')
            "
          >
            <span style="margin-right: 0.2em">Manajemen</span>
            <span v-if="permissions.includes('manage-user')">Pengguna</span>
            <span
              v-if="
                permissions.includes('manage-user') &&
                permissions.includes('manage-role')
              "
            >
              &#44;&nbsp;
            </span>
            <span v-if="permissions.includes('manage-role')">Peran</span>
            <span
              v-if="
                (permissions.includes('manage-user') ||
                  permissions.includes('manage-role')) &&
                permissions.includes('manage-customer')
              "
            >
              &nbsp;&#38;&nbsp;
            </span>
            <span v-if="permissions.includes('manage-customer')"
              >Pelanggan</span
            >
          </v-subheader>
          <!-- User Management -->
          <v-list-item
            v-if="permissions.includes('manage-user')"
            to="/users"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Pengguna</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Role Management -->
          <v-list-item
            v-if="permissions.includes('manage-role')"
            to="/role"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Peran</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Pelanggan Management -->
          <v-list-item
            v-if="permissions.includes('manage-customer')"
            to="/customers"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Pelanggan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            v-if="
              permissions.includes('manage-category') ||
              permissions.includes('manage-brand') ||
              permissions.includes('manage-product') ||
              permissions.includes('manage-carousel') ||
              permissions.includes('manage-shipping-cost')
            "
            >Manajemen E-commerce</v-subheader
          >
          <!-- Category Management -->
          <v-list-group
            v-if="permissions.includes('manage-category')"
            :value="false"
            prepend-icon="mdi-shape"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title>Kategori</v-list-item-title>
            </template>
            <v-list-item link to="/main-category">
              <v-list-item-title>Kategori Utama</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/sub-category">
              <v-list-item-title>Subkategori</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/category">
              <v-list-item-title>Kategori Barang</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-if="permissions.includes('manage-brand')"
            link
            to="/brand"
          >
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Merek </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="permissions.includes('manage-product')"
            :value="false"
            prepend-icon="mdi-package-variant"
            no-action
          >
            <!-- Product Management -->
            <template v-slot:activator>
              <v-list-item-title>Produk</v-list-item-title>
            </template>

            <v-list-item link to="/product/ready-stock">
              <v-list-item-title>Ready Stock</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item link to="/list-product">
            <v-list-item-title>Product</v-list-item-title>
          </v-list-item> -->

            <v-list-item link to="/product/pre-order">
              <v-list-item-title>Pre Order</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-if="permissions.includes('manage-carousel')"
            to="/carousel"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-image-multiple</v-icon>
            </v-list-item-icon>
            <!-- carousel -->
            <v-list-item-content>
              <v-list-item-title>Banner</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="permissions.includes('manage-about')"
            to="/about"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <!-- about -->
            <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="permissions.includes('manage-faq')" to="/faq" link>
            <v-list-item-icon>
              <v-icon>mdi-frequently-asked-questions</v-icon>
            </v-list-item-icon>
            <!-- faq -->
            <v-list-item-content>
              <v-list-item-title>Faq</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="permissions.includes('manage-shipping-cost')"
            to="/shipping-cost"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-truck-outline</v-icon>
            </v-list-item-icon>
            <!-- carousel -->
            <v-list-item-content>
              <v-list-item-title>Ongkos Kirim</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Voucher Management -->
          <!-- <v-list-item v-if="permissions.includes('voucher')" link to="/voucher">
          <v-list-item-icon
            ><v-icon>mdi-ticket-percent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Voucher</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
          <!-- end voucher -->
          <!-- Discount Management -->
          <!-- <v-list-item link to="/discount">
          <v-list-item-icon><v-icon>mdi-sale</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Discount</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
          <!-- end discount -->
          <!-- FINANCE NAV -->
          <v-subheader
            v-if="
              permissions.includes('manage-purchase-order') ||
              permissions.includes('manage-freight') ||
              permissions.includes('manage-product-customer') ||
              permissions.includes('manage-bank') ||
              permissions.includes('manage-currency')
            "
            >Keuangan</v-subheader
          >

          <v-list-item
            v-if="permissions.includes('manage-bank')"
            link
            to="/bank"
          >
            <v-list-item-icon><v-icon> mdi-bank </v-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Bank</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="permissions.includes('manage-currency')"
            link
            to="/currency"
          >
            <v-list-item-icon
              ><v-icon> mdi-currency-usd </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mata Uang</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="
              permissions.includes('manage-purchase-order') ||
              permissions.includes('manage-freight') ||
              permissions.includes('manage-product-customer')
            "
            :value="false"
            prepend-icon="mdi-file-document-outline"
            no-action
          >
            <!-- Product Management -->
            <template v-slot:activator>
              <v-list-item-title>Purchase Order</v-list-item-title>
            </template>

            <v-list-item
              v-if="permissions.includes('manage-purchase-order')"
              link
              to="/purchase-order/list"
            >
              <v-list-item-content>
                <v-list-item-title>List Purchase Order</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="permissions.includes('manage-freight')"
              link
              to="/purchase-order/list-freight"
            >
              <v-list-item-content>
                <v-list-item-title>Freight</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="permissions.includes('manage-product-customer')"
              link
              to="/purchase-order/link"
            >
              <v-list-item-content>
                <v-list-item-title>Pelunasan</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-if="permissions.includes('manage-payback')"
            link
            to="/payback"
          >
            <v-list-item-icon
              ><v-icon> mdi-account-credit-card-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Payback</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- END FINANCE NAV -->

          <!-- Start Report Nav ( Disable Because Not Yet Backend ) -->
          <!-- TODO: add permission condition -->

          <v-subheader
            v-if="
              permissions.includes('manage-report-purchase') ||
              permissions.includes('manage-report-sales-and-customer')
            "
            >Laporan</v-subheader
          >

          <!-- <v-list-item link to="/report/transaction">
          <v-list-item-icon><v-icon> mdi-sync </v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Transaksi</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/report/order-to-factory">
          <v-list-item-icon><v-icon> mdi-factory </v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Oerder ke Pabrik</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/report/all">
          <v-list-item-icon><v-icon> mdi-finance </v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Laporan</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

          <v-list-item
            v-if="permissions.includes('manage-report-purchase')"
            link
            to="/report/purchase"
          >
            <v-list-item-icon
              ><v-icon> mdi-chart-areaspline-variant </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pembelian</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="permissions.includes('manage-report-sales-and-customer')"
            link
            to="/report/sales&customer"
          >
            <v-list-item-icon
              ><v-icon> mdi-chart-areaspline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Penjualan & Pelanggan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- End Report Nav -->
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-btn icon large @click="isNav = !isNav"
          ><v-icon large>mdi-menu</v-icon></v-btn
        >
      </div>

      <v-spacer></v-spacer>
      <div>
        <notification-component />
        <v-menu bottom offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </button>
          </template>
          <v-list>
            <v-list-item link to="/profile" class="option-status">
              <v-list-item-title> Lihat Profil </v-list-item-title>
            </v-list-item>
            <v-list-item link class="option-status" @click="logout">
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- END NAVIGATION DRAWER -->
  </header>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
import NotificationComponent from "@/components/NotificationComponent.vue";
import store from "@/store";
import axiosAdmin from "@/plugins/axiosAdmin";
import { mapGetters } from "vuex";
export default defineComponent({
  components: { NotificationComponent },
  setup() {
    const items = [
      { title: "Photos", icon: "mdi-image" },
      { title: "About", icon: "mdi-help-box" },
    ];
    const admins = [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ];
    const isNav = ref(true);

    return { items, admins, isNav };
  },
  mounted() {
    store.dispatch("getNotifications");
  },
  computed: {
    ...mapGetters(["account", "permissions"]),
    accountingUrl() {
      return process.env.VUE_APP_ACCOUNTING_BASE_URL;
    },
  },
  methods: {
    logout() {
      axiosAdmin.post("/api/admin/logout").then((response) => {
        if (response.data.status) {
          localStorage.removeItem("l4t-token");
          localStorage.removeItem("accountActivated");
          localStorage.removeItem("accountPermission");
          this.$router.push("/login");
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped></style>
