const formatter = {
  setup() {
    // format input to rupiah style
    const formatInputRupiah = (value) => {
      const clearStr = !value ? "" : value.toString().replace(/[^0-9]/g, "");
      return (clearStr + "")
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // format number to rupiah style
    const formatStyleRupiah = (money) => {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    };
    // format number to usd style
    const formatUSD = (money) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(money);
    };
    const formatInputOtherCurrency = (value) => {
      // format coma style form 1000 to 1,0000
      const inputValue = value.toString();
      const formatComa = (value) => {
        return value
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      if (inputValue.indexOf(".") >= 0) {
        const decimalPos = inputValue.indexOf(".");
        let leftSide = inputValue.substring(0, decimalPos);
        let rightSide = inputValue.substring(decimalPos);
        rightSide = rightSide.substring(1, 3);
        leftSide = formatComa(leftSide);
        const result = leftSide + "." + rightSide;

        return result;
      } else return formatComa(inputValue);
    };

    const parseNumberOtherCurrency = (value) => {
      // format 1,000,000.89 to 1000000.89
      const clearStr = value.toString().replaceAll(",", "");
      return Number(clearStr);
    };

    // idr currency to number
    const parseNumberIdr = (value) => {
      const clearStr = value.toString().replaceAll(".", "").replace(",", ".");
      return Number(clearStr);
    };

    return {
      formatInputRupiah,
      formatStyleRupiah,
      formatUSD,
      formatInputOtherCurrency,
      parseNumberOtherCurrency,
      parseNumberIdr,
    };
  },
};
export default formatter;
