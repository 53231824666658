import axiosAdmin from "@/plugins/axiosAdmin";

const moduleCategory = {
  state: () => ({
    listMainCategory: [],
    listSubCategory: [],
    listCategory: [],
    filteredSubCategory: [],
    filteredItemCategory: [],
  }),
  mutations: {
    addMainCategory(state, payload) {
      state.mainCategory = payload;
    },
    commitListMainCategory(state, payload) {
      state.listMainCategory = payload;
    },
    setListSubCategory(state, payload) {
      state.listSubCategory = payload;
    },
    setFilteredSubCategory(state, payload) {
      state.filteredSubCategory = payload;
    },
    setListCategory(state, payload) {
      state.listCategory = payload;
    },
    setFilteredItemCategory(state, payload) {
      state.filteredItemCategory = payload;
    },
  },
  actions: {
    // MAIN CATEGORY
    // send new main category data backend
    async addMainCategory(context, payload) {
      await axiosAdmin
        .post("/api/admin/main-category", {
          name: payload.name,
          code: payload.code,
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // get list main category data from backend
    async getListMainCategory({ commit }, keyword) {
      const qs = new URLSearchParams();
      if (keyword) {
        qs.append("q", keyword);
      }
      await axiosAdmin
        .get(`/api/admin/main-category?${qs.toString()}`)
        .then((resp) => {
          const listMainCategory = resp.data.data;
          commit("commitListMainCategory", listMainCategory);
          return Promise.resolve();
        });
    },
    // send updated main category data to backend
    async editMainCategory(context, payload) {
      await axiosAdmin
        .put("/api/admin/main-category/" + payload.uuid, {
          name: payload.name,
          code: payload.code,
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // send deleted main category uuid to backend
    async deleteMainCategory(context, payload) {
      await axiosAdmin
        .delete("/api/admin/main-category/" + payload.uuid)
        .then((resp) => {
          return Promise.resolve(resp);
        });
    },

    // SUB CATEGORY
    // get list sub category data from backend
    async getListSubCategory({ commit }, params) {
      const qs = new URLSearchParams();
      if (params.keyword) {
        qs.append("q", params.keyword);
      }
      if (typeof params.mainCategoryId === "object") {
        params.mainCategoryId.forEach((category) => {
          qs.append("main_category_id[]", category.value);
        });
      }
      await axiosAdmin
        .get(`/api/admin/sub-category?${qs.toString()}`)
        .then((resp) => {
          const listSubCategory = resp.data.data;
          commit("setListSubCategory", listSubCategory);
        });
    },
    // send new sub category data to backend
    async addSubCategory(context, payload) {
      await axiosAdmin
        .post("/api/admin/sub-category", {
          main_category_id: payload.mainCategoryId,
          name: payload.name,
          code: payload.code.toUpperCase(),
        })
        .then(() => {
          return Promise.resolve();
        });
    },
    // send updated sub category data to backend
    async editSubCategory(context, payload) {
      const data = new URLSearchParams();
      data.append("_method", "PUT");
      data.append("main_category_id", payload.mainCategoryId);
      data.append("name", payload.name);
      data.append("code", payload.code.toUpperCase());
      await axiosAdmin
        .post("/api/admin/sub-category/" + payload.id, data)
        .then(() => {
          return Promise.resolve();
        });
    },
    // get list sub category from backend with query params
    async filterSubCategory({ commit }, payload) {
      let query = "";
      payload.forEach((item) => {
        if (query === "") {
          query = `main_category_id[]=${item}`;
        } else {
          query = query + `&main_category_id[]=${item}`;
        }
      });
      commit("setFilteredSubCategory", []);
      await axiosAdmin.get(`/api/admin/sub-category?${query}`).then((resp) => {
        const result = resp.data.data;
        commit("setFilteredSubCategory", result);
      });
    },
    // send deleted sub category uuid to backend
    async deleteSubCategory(context, payload) {
      await axiosAdmin.delete("/api/admin/sub-category/" + payload).then(() => {
        return Promise.resolve();
      });
    },
    // CATEGORY
    // get list category data from backend
    async getListCategory({ commit }, params) {
      const qs = new URLSearchParams();
      if (params.keyword) {
        qs.append("q", params.keyword);
      }
      if (params.mainCategoryId) {
        qs.append("main_category_id[]", params.mainCategoryId);
      }
      if (params.subCategoryId?.length > 0) {
        params.subCategoryId.forEach((category) => {
          qs.append("sub_category_id[]", category.value);
        });
      }
      await axiosAdmin
        .get(`/api/admin/category?${qs.toString()}`)
        .then((resp) => {
          const listCategory = resp.data.data;

          commit("setListCategory", listCategory);
          return Promise.resolve();
        });
    },
    // get filtered category data from backend with query params
    async filterItemCategory({ commit }, payload) {
      let query = "";
      payload.forEach((item) => {
        if (query === "") {
          query = `sub_category_id[]=${item}`;
        } else {
          query = query + `&sub_category_id[]=${item}`;
        }
      });
      await axiosAdmin.get("/api/admin/category?" + query).then((resp) => {
        const listCategory = resp.data.data;
        commit("setFilteredItemCategory", listCategory);
        return Promise.resolve();
      });
    },
    // send new category data to backend
    async addCategory(context, payload) {
      const data = {
        sub_category_id: payload.subCategoryId,
        name: payload.categoryName,
        code: payload.code.toUpperCase(),
      };
      await axiosAdmin.post("/api/admin/category", data).then(() => {
        return Promise.resolve();
      });
    },
    // send updated category data to backend
    async editCategory(context, payload) {
      const data = {
        sub_category_id: payload.subCategoryId,
        name: payload.categoryName,
        code: payload.code.toUpperCase(),
      };
      await axiosAdmin
        .put("/api/admin/category/" + payload.uuid, data)
        .then(() => {
          return Promise.resolve();
        });
    },
    // send deleted category uuid to backend
    async deleteCategory(context, payload) {
      await axiosAdmin
        .delete("/api/admin/category/" + payload.uuid)
        .then(() => {
          return Promise.resolve();
        });
    },
  },
  getters: {
    listMainCategory(state) {
      return state.listMainCategory;
    },
    optionsMainCategory(state) {
      const options = [
        {
          text: "Semua Kategori",
          value: "",
        },
      ];
      state.listMainCategory.forEach((item) => {
        options.push({ text: item.name, value: item.id });
      });
      return options;
    },
    optionsMainCategoryCombobox(state) {
      return state.listMainCategory.map((item) => {
        const data = {
          text: item.name,
          value: item.id,
        };
        return data;
      });
    },
    // sub category
    listSubCategory(state) {
      return state.listSubCategory;
    },
    filteredSubCategory(state) {
      return state.filteredSubCategory.map((item) => {
        const data = {
          text: item.name,
          value: item.id,
        };
        return data;
      });
    },
    optionsItemCategory(state) {
      return state.filteredItemCategory.map((item) => {
        const data = {
          text: item.name,
          value: item.id,
        };
        return data;
      });
    },
    // category
    listCategory(state) {
      return state.listCategory;
    },
  },
};

export default moduleCategory;
