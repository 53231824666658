import axiosAdmin from "@/plugins/axiosAdmin";

const moduleCustomer = {
  state: () => ({
    listCustomer: [],
    listCustomerType: [{ id: "", name: "" }],
    listCusromerTransaction: [],
    optionCustomer: [],
    optionCustomerOrigin: [],
  }),
  mutations: {
    setListCustomer(state, payload) {
      state.listCustomer = payload;
    },
    setListCustomerType(state, payload) {
      state.listCustomerType = payload;
    },
    setListCustomerTransaction(state, payload) {
      state.listCusromerTransaction = payload;
    },
    setAllOptionCustomer(state, payload) {
      state.optionCustomer = payload;
      state.optionCustomerOrigin = payload;
    },
    setOptionCustomer(state, payload) {
      state.optionCustomer = payload;
    },
  },
  actions: {
    // get list customer type data from backend
    async getListCustomerType({ commit }) {
      try {
        const res = await axiosAdmin.get("/api/customer-type");
        const type = res.data.data;
        type.pop();
        commit("setListCustomerType", type);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    },
    // get customer transaction history data from backend
    async getCustomerTransaction({ commit }, query) {
      try {
        const qs = new URLSearchParams();
        qs.append("customer_id", query.id);
        if (query.startDate) {
          qs.append("start_date", query.startDate);
        }
        if (query.endDate) {
          qs.append("end_date", query.endDate);
        }
        if (query.q) {
          qs.append("q", query.q);
        }
        const res = await axiosAdmin.get(
          `/api/customer/transaction?${qs.toString()}`
        );
        commit("setListCustomerTransaction", res.data.data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    },
    // get customer data from backend
    async getOptionsCustomer({ commit }) {
      await axiosAdmin
        .get("/api/admin/customer")

        .then((resp) => {
          commit("setAllOptionCustomer", resp.data.data);
          return Promise.resolve();
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // get customer transaction detail data from backend
    async getCustomerDetailTransaction(_, uuid) {
      try {
        return axiosAdmin.get(`/api/customer/transaction/${uuid}/detail`);
      } catch (error) {
        return Promise.reject();
      }
    },
  },
  getters: {
    listCustomerType(state) {
      return state.listCustomerType;
    },
    listCustomerTransaction(state) {
      return state.listCusromerTransaction;
    },
    optionsCustomer(state) {
      return state.optionCustomer;
    },
    originOptionsCustomer(state) {
      return state.optionCustomerOrigin;
    },
  },
};

export default moduleCustomer;
