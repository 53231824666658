<template>
  <div>
    <v-dialog v-model="isShowDialog" width="500">
      <v-card>
        <v-card-text class="text-center py-6 px-4">
          <v-icon style="font-size: 84px" color="red">mdi-close</v-icon>
          <h4 class="text-h4 font-weight-medium">Error</h4>
          <p>Something went wrong. Please try again.</p>
          <v-btn color="red" width="120px" class="white--text text-h4"
            >OK</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogTryAgain",
  data: () => ({
    isShowDialog: false,
  }),
};
</script>
