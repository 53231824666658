import axiosAdmin from "@/plugins/axiosAdmin";

const shippingModule = {
  state: () => ({
    listProvince: [],
    listOngkir: [],
  }),
  mutations: {
    setListProvince(state, payload) {
      state.listProvince = payload;
    },
    setListOngkir(state, payload) {
      state.listOngkir = payload;
    },
  },
  actions: {
    // get all provinces data from backend
    async getListProvince({ commit }) {
      await axiosAdmin
        .get("/api/province")
        .then((resp) => {
          commit("setListProvince", resp.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get all list shipping fee from backend
    async getListOngkir({ commit }) {
      await axiosAdmin
        .get("/api/ongkir")
        .then((resp) => {
          commit("setListOngkir", resp.data);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // send updated shipping fee to backend
    async putOngkir(context, payload) {
      await axiosAdmin
        .put(`api/ongkir/${payload.ongkirID}?amount=${payload.amount}`)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
  getters: {
    listProvince(state) {
      return state.listProvince;
    },
    listOngkir(state) {
      return state.listOngkir;
    },
  },
};

export default shippingModule;
