import axiosAdmin from "@/plugins/axiosAdmin";

const roleModule = {
  state: () => ({
    listRole: [],
    listPermissions: [{ id: "", name: "" }],
  }),
  mutations: {
    setRole(state, payload) {
      state.listRole = payload;
    },
    setPermission(state, payload) {
      state.listPermissions = payload;
    },
  },
  actions: {
    // get all role data from backend
    async getRoles({ commit }) {
      await axiosAdmin
        .get("/api/admin/roles")
        .then((resp) => {
          commit("setRole", resp.data.data);
          return Promise.resolve(resp.data);
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // get all permission data from backend
    async getPermission({ commit }) {
      await axiosAdmin
        .get("/api/admin/roles/get-permission")
        .then((resp) => {
          commit("setPermission", resp.data);
          return Promise.resolve(resp.data);
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    // send new role data to backend
    addRole(_, payload) {
      return axiosAdmin.post("/api/admin/roles", payload);
    },
    // send deleted role id to backend
    deleteRole(_, id) {
      return axiosAdmin.delete(`/api/admin/roles/${id}`);
    },
    // send updated role data to backend
    async updateRole({ dispatch }, payload) {
      const data = new URLSearchParams();
      data.append("name", payload.name);
      const permissions = new URLSearchParams();
      payload.permissions.forEach((element) => {
        permissions.append("permissions[]", element);
      });
      // payload.permission.forEach((permission) => {
      //   data.append("permission[]", permission);
      // });
      return axiosAdmin.put(`/api/admin/roles/${payload.id}`, data).then(() => {
        axiosAdmin
          .patch(`/api/admin/roles/${payload.id}/sync-permission`, permissions)
          .then(() => {
            dispatch("getRoles");
          });
      });
    },
    // activate role
    activateRole(_, id) {
      return axiosAdmin.patch(`/api/admin/roles/${id}/activate`);
    },
    // deactivate role
    deactivateRole(_, id) {
      return axiosAdmin.patch(`/api/admin/roles/${id}/deactivate`);
    },
  },
  getters: {
    rolesName(state) {
      const roles = state.listRole.map((role) => {
        return {
          text: role.name.charAt(0).toUpperCase() + role.name.slice(1),
          value: role.name,
        };
      });
      return roles;
    },
    optionRoles(state) {
      const roles = state.listRole.map((role) => {
        return {
          value: role.name,
          text: role.name.charAt(0).toUpperCase() + role.name.slice(1),
        };
      });
      return roles;
    },
    listRole(state) {
      return state.listRole;
    },
    listPermissions(state) {
      return state.listPermissions.map((el) => {
        return {
          text: el.name
            .split("-")
            .map((char) => {
              return char.charAt(0).toUpperCase() + char.slice(1);
            })
            .join(" "),
          value: el.name,
        };
      });
    },
  },
};

export default roleModule;
