<template>
  <v-app>
    <!-- <component :is="$route.meta.nav || 'div'"></component> -->
    <the-navigation v-if="$route.meta.nav"></the-navigation>
    <v-main class="grey lighten-3">
      <v-snackbar
        v-model="snackError"
        color="error"
        transition="scale-transition"
        top
        right
      >
        <div>
          <v-icon class="mb-1 mr-2">mdi-close</v-icon>
          <span class="font-weight-medium">
            {{ snackErrorMessage }}
          </span>
          <!-- <p class="white--text mb-0">
          </p> -->
        </div>
      </v-snackbar>
      <v-snackbar
        v-model="snackSuccess"
        :timeout="3000"
        color="success"
        top
        right
        transition="scale-transition"
      >
        <v-icon class="mb-1 mr-2">mdi-check</v-icon>
        <span class="font-weight-medium"> {{ snackSuccessMessage }} </span>
        <!-- <p class="white--text text-subtitle-2 mb-0">
        </p> -->
      </v-snackbar>
      <dialog-try-again></dialog-try-again>
      <router-view :key="$route.fullPath" />
      <overlay-loading></overlay-loading>
    </v-main>
  </v-app>
</template>

<script>
import TheNavigation from "@/components/shared/TheNavigation.vue";
import OverlayLoading from "@/components/shared/OverlaySpinner.vue";
import DialogTryAgain from "@/components/DialogTryAgain.vue";
import { mapGetters } from "vuex";
import store from "@/store/index";
export default {
  name: "App",
  components: {
    TheNavigation,
    OverlayLoading,
    DialogTryAgain,
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["snackSuccessMessage", "snackErrorMessage"]),
    snackSuccess: {
      get() {
        return this.$store.getters.snackSuccess;
      },
      set(val) {
        store.state.snackbar.success = val;
      },
    },
    snackError: {
      get() {
        return this.$store.getters.snackError;
      },
      set(val) {
        store.state.snackbar.error = val;
      },
    },

    errorAlert: {
      get() {
        return this.$errSnack.show;
      },
      set(val) {
        this.$errSnack.show = val;
      },
    },
    errorMessage() {
      return this.$errSnack.message;
    },
    showNav() {
      return this.$route.meta.showNav === false ? false : true;
    },
  },
};
</script>
